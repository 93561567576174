// src/components/LanguagePicker.js
import React from "react";
import { Select, useBreakpointValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const LanguagePicker = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <Select
      width={{ base: "90px", md: "115px" }}
      color="Neutral.100"
      borderColor="Neutral.400"
      _hover={{
        bg: "Neutral.700",
      }}
      onChange={changeLanguage}
      defaultValue={i18n.language}
    >
      <option value="en">
        {useBreakpointValue({ base: "EN", md: "English" })}
      </option>
      <option value="jp">日本語</option>
    </Select>
  );
};

export default LanguagePicker;
