import {
  Box,
  Heading,
  Flex,
  Container,
  Text,
  Button,
  Stack,
  Image,
  Icon,
  Link,
  useColorModeValue,
  createIcon,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("about_us");

  return (
    <Box>
      <Container maxW={{ base: "3xl", sm: "3xl", md: "3xl", lg: "4xl" }}>
        <Stack as={Box} spacing={{ base: 8, md: 14 }} py={{ base: 12, md: 16 }}>
          <Text
            className={i18n.language === "jp" ? "jp" : "en"}
            color={"Neutral.200"}
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "2xl", md: "2xl", lg: "2xl" }}
            textAlign={"center"}
          >
            {t("title")}
          </Text>
          <Box
            color="Neutral.200"
            bg="Neutral.800"
            fontWeight="500"
            fontSize={{ base: "lg", sm: "lg", md: "lg", lg: "lg" }}
            textAlign={"left"}
            rounded={"2xl"}
            boxShadow={"2xl"}
            px={{ base: 12, md: 20 }}
            py={10}
          >
            {t("description_1", "")}
            <br />
            <br />
            {t("description_2", "")}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
