import axios from "axios";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];

export default function Contact() {
  const { t, i18n } = useTranslation("contact");
  const endpoint = "/contact/send_email";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [inquiryID, setInquiryID] = useState(null);
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    setSubmitted(true);

    try {
      console.log(`Calling Merito API: ${endpoint}`);
      const response = await axios.post(`${MERITO_API_HOST}${endpoint}`, {
        name: name,
        email: email,
        subject: subject,
        message: message,
      });
      console.log("Inquiry:", response.data.inquiry);
      setInquiryID(response.data.inquiry.id);
      // setMessage(msg);  // TODO
    } catch (err) {
      const errMsg = `Failed to send the contact form:\n${err}`;
      console.log(errMsg);
      alert(errMsg);
      // setErrorMessage(errMsg);  // TODO
    }
  };

  useEffect(() => {
    if (!submitted) {
      setDisabled(name && email && message ? false : true);
    }
  }, [name, email, message]);

  return (
    <Flex>
      <Stack
        className={i18n.language === "jp" ? "jp" : "en"}
        spacing={8}
        mx={"auto"}
        maxW={"2xl"}
        pt={12}
        pb={16}
        px={6}
      >
        <Stack>
          <Text
            color={"Neutral.0"}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "3xl", md: "4xl", lg: "4xl" }}
            textAlign={"center"}
          >
            {t("title")}
          </Text>
          {inquiryID && (
            <Text
              fontSize={"lg"}
              textAlign={"left"}
              color={"GREEN.8"}
              px={6}
              pt={4}
            >
              {t("description_top_1")}
              <br />
              {t("description_top_2")}
              {inquiryID}
            </Text>
          )}
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"xl"}
          p={9}
        >
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>{t("name")}</FormLabel>
              <Input
                onChange={(e) => setName(e.target.value)}
                id="name"
                value={name}
                type="text"
                // placeholder="John Doe"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>{t("email")}</FormLabel>
              <Input
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                value={email}
                type="email"
                // placeholder="name@host.com"
              />
            </FormControl>
            <FormControl>
              <FormLabel>{t("subject")}</FormLabel>
              <Input
                onChange={(e) => setSubject(e.target.value)}
                id="subject"
                value={subject}
                type="text"
                placeholder=""
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>{t("message")}</FormLabel>
              <Textarea
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                value={message}
                type="text"
                placeholder=""
              />
            </FormControl>
            <Stack pt={4} px={1}>
              <Text align={"left"} color={"gray.500"} fontSize="14">
                {t("description_bottom_1")}
                <Text as="span" color={"GREEN.7"}>
                  {t("description_bottom_2")}
                </Text>
                {t("description_bottom_3")}
              </Text>
            </Stack>
            <Stack py={3}>
              <Button
                className={i18n.language === "jp" ? "jp" : "en"}
                onClick={handleSubmit}
                isDisabled={disabled}
                loadingText="Submitting"
                bg={"GREEN.7"}
                color={"white"}
                _hover={{
                  bg: "GREEN.8",
                }}
                _disabled={{
                  bg: "GREEN.6",
                  cursor: "not-allowed",
                }}
              >
                {t("send_button")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
