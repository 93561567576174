import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en", // Initial language
    fallbackLng: "en", // Fallback to English in case a translation is missing
    debug: true,
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ns: [
      "header",
      "footer",
      "home",
      "creators",
      "buyers",
      "about_us",
      "contact",
      "portal",
    ], // List of namespaces
    defaultNS: "home", // Default namespace
  });

export default i18n;
