import axios from "axios";
import React, { useContext, useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Link,
  Textarea,
  Button,
  Heading,
  Text,
  VStack,
  HStack,
  useToast,
  FormHelperText,
  InputGroup,
  InputLeftAddon,
  Select,
  Modal,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { AuthContext } from "../context/AuthContext";
import { useFormData } from "./FormDataContext";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];

export default function ScienceDealsCreation() {
  const { currentUser, setCurrentUser, signedIn, setSignedIn } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formData: contextFormData, updateFormData } = useFormData();
  const endpoint = "/science_deals/create_project";

  const [currentStep, setCurrentStep] = useState(1);
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);
  const [formDetails, setFormDetails] = useState({
    project_name: null,
    project_owner: null,
    organization: null,
    jurisdiction: null,
    project_description: null,
    project_goal: null,
    project_start_date: null,
    project_duration: null,
    suggested_price_currency: null,
    suggested_price: null,
    project_image_url: null,
    project_file_url: null,
    disclaimer_checked_at: null,
    user_id_seller: currentUser.user_id,
    created_at: null,
  });

  const getCurrentJSTDateTime = () => {
    const jstMoment = moment.tz("Asia/Tokyo");
    // ISO 8601 format with timezone
    const jstString = jstMoment.format();
    return jstString;
  };

  const setLoading = (isLoading) => {
    if (isLoading) {
      onOpen();
    } else {
      onClose();
    }
  };

  const handleNext = () => {
    const nextStep = currentStep === 5 ? currentStep : currentStep + 1;
    setCurrentStep(nextStep);
  };

  const handleBack = () => {
    const prevStep = currentStep === 1 ? currentStep : currentStep - 1;
    setCurrentStep(prevStep);
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    updateFormData({ [name]: value });
  };

  const uploadFile = (file_type, file) => {
    const formData = new FormData();
    formData.append("file", file); // 'file' is the key expected by the multer on the server side
    setLoading(true);
    fetch(
      "https://demo-api-dot-merito-prototype.uc.r.appspot.com/upload-file",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.url) {
          setFormDetails((prevDetails) => ({
            ...prevDetails,
            [file_type]: data.url,
          }));
          updateFormData({ [file_type]: data.url });
          alert("File uploaded successfully! URL: " + data.url);
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        alert("Failed to upload file.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleProjectImageUpload = (event) => {
    // event.preventDefault();
    // uploadFile("project_image_url", event.target.files[0]);
  };

  const handleProjectFileUpload = (event) => {
    // event.preventDefault();
    // uploadFile("project_file_url", event.target.files[0]);
  };

  const handleDisclaimerCheck = () => {
    const current = disclaimerChecked;

    let currentJSTDateTime = null;
    if (!current) {
      currentJSTDateTime = getCurrentJSTDateTime();
    }

    setFormDetails((prevDetails) => ({
      ...prevDetails,
      disclaimer_checked_at: currentJSTDateTime,
    }));
    updateFormData({ disclaimer_checked_at: currentJSTDateTime });
    setDisclaimerChecked(!current);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const currentJSTDateTime = getCurrentJSTDateTime();
    // setFormDetails((prevDetails) => ({
    //   ...prevDetails,
    //   created_at: currentJSTDateTime,
    // }));
    // updateFormData({ created_at: currentJSTDateTime });

    // console.log("\n\nformDetails", formDetails);

    const projectData = {
      project_name: formDetails.project_name,
      project_owner: formDetails.project_owner,
      organization: formDetails.organization,
      jurisdiction: formDetails.jurisdiction,
      project_description: formDetails.project_description,
      project_goal: formDetails.project_goal,
      project_start_date: formDetails.project_start_date,
      project_duration: formDetails.project_duration,
      suggested_price_currency: formDetails.suggested_price_currency,
      suggested_price: formDetails.suggested_price,
      project_image_url:
        "https://storage.googleapis.com/merito-project-files/thumbnail.jpg",
      project_file_url:
        "https://storage.googleapis.com/merito-project-files/project_file.png",
      disclaimer_checked_at: formDetails.disclaimer_checked_at,
      user_id_seller: currentUser.user_id,
      created_at: currentJSTDateTime,
    };
    console.log(`Calling Merito API: ${endpoint}`);
    console.log(`Project Data: ${projectData}`);

    // const response = await axios.post(
    //   `${MERITO_API_HOST}${endpoint}`,
    //   projectData
    // );
    // console.log("project_id:", response.data.project.project_id);

    navigate("/science-deals/sra-generation");
  };

  // const handlePDFGeneration = async (event) => {
  //   event.preventDefault();

  //   // Get a string of YYYY-MM-DD
  //   const currentJSTDateTime = getCurrentJSTDateTime();
  //   const currentJSTDate = currentJSTDateTime.split("T")[0];

  //   try {
  //     console.log(`Calling Merito SRA API: ${endpoint}`);
  //     const response = await axios.post(`${MERITO_SRA_API_HOST}${endpoint}`, {
  //       project_name: formDetails.project_name,
  //       organization: formDetails.organization,
  //       created_at: currentJSTDate,
  //     });

  //     const data = response.data;
  //     const pdfPath = data.url;
  //     console.log("\ndata:", data);
  //     alert(`PDF generated successfully! Path: ${pdfPath}`);
  //     // setMessage(msg);  // TODO
  //   } catch (err) {
  //     const errMsg = `Failed to generate a PDF file:\n${err}`;
  //     console.log(errMsg);
  //     alert(errMsg);
  //     // setErrorMessage(errMsg);  // TODO
  //   }
  // };

  return (
    <Flex
      w="full"
      align="top"
      justifyContent="center"
      minH="100vh"
      p={6}
      pt={14}
    >
      <Box
        w="full"
        maxW="1200px"
        h="83vh"
        borderRadius="xl"
        boxShadow="4xl"
        overflow="hidden"
      >
        <Flex direction={{ base: "column", md: "row" }} h="83vh">
          {/* Left Title */}
          <Box
            w={{ base: "100%", md: "50%" }}
            bg="ScienceDeals.800"
            color="Neutral.50"
            fontWeight={300}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            position="relative"
            p={10}
            zIndex={0}
          >
            <Text fontSize="4xl" zIndex={1}>
              Science Deals
            </Text>
            <Text fontSize="md" mt={2} zIndex={1}>
              Make science funding more{" "}
              <Text as="span" textDecoration="underline">
                merito
              </Text>
              cratic.
            </Text>
            <Box
              position="absolute"
              top="7%"
              left="5%"
              w="110px"
              h="110px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              top="6%"
              right="-17%"
              w="310px"
              h="310px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              bottom="25%"
              left="16%"
              w="75px"
              h="75px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              bottom="14%"
              right="37%"
              w="220px"
              h="220px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
            <Box
              position="absolute"
              bottom="3%"
              right="37%"
              w="120px"
              h="120px"
              bg="ScienceDeals.600"
              borderRadius="full"
              filter="blur(10px)"
              zIndex={0}
            />
          </Box>

          {/* Form */}
          <Box
            w={{ base: "100%", md: "50%" }}
            h="83vh"
            bg="GREEN.1"
            color="GREEN.9"
            fontWeight={400}
            display="flex"
            flexDirection="column"
            justifyContent="top"
            alignItems="left"
            textAlign="left"
            position="relative"
            overflow="hidden"
            p={10}
            zIndex={1}
          >
            {/* <VStack spacing={4} as="form" onSubmit={handleSubmit}> */}
            <VStack spacing={4} as="form">
              <Heading color="GREEN.9" fontWeight={400} mb={4}>
                Create Project
              </Heading>

              <FormControl
                id="project_name"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Project Name</FormLabel>
                <Input
                  type="text"
                  name="project_name"
                  value={formDetails.project_name}
                  onChange={handleInputChange}
                  placeholder="Title of the project"
                />
              </FormControl>

              <FormControl
                id="project_owner"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Project Owner</FormLabel>
                <Input
                  type="text"
                  name="project_owner"
                  value={formDetails.project_owner}
                  onChange={handleInputChange}
                  placeholder="Name of the entity who owns the project"
                />
              </FormControl>

              <FormControl
                id="organization"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Organization</FormLabel>
                <Input
                  type="text"
                  name="organization"
                  value={formDetails.organization}
                  onChange={handleInputChange}
                  placeholder="Name of their affiliated organization/university"
                />
              </FormControl>

              <FormControl
                id="jurisdiction"
                isRequired
                hidden={currentStep !== 1}
              >
                <FormLabel>Jurisdiction</FormLabel>
                <Select
                  name="jurisdiction"
                  value={formDetails.jurisdiction}
                  onChange={handleInputChange}
                  defaultValue="Select option"
                  focusBorderColor="brand.400"
                >
                  <option value="Select option" disabled>
                    Select option
                  </option>
                  <option value="United States">United States</option>
                  <option value="Japan">Japan</option>
                </Select>
              </FormControl>

              <FormControl
                id="project_description"
                isRequired
                hidden={currentStep !== 2}
              >
                <FormLabel>Project Description</FormLabel>
                <Textarea
                  name="project_description"
                  value={formDetails.project_description}
                  onChange={handleInputChange}
                  placeholder="Description of the project"
                  size="sm"
                />
              </FormControl>

              <FormControl
                id="project_goal"
                isRequired
                hidden={currentStep !== 2}
              >
                <FormLabel>Project Goal</FormLabel>
                <Input
                  type="text"
                  name="project_goal"
                  value={formDetails.project_goal}
                  onChange={handleInputChange}
                  placeholder="Goal of the project"
                />
              </FormControl>

              <FormControl
                id="project_start_date"
                isRequired
                hidden={currentStep !== 3}
              >
                <FormLabel>Project Start Date</FormLabel>
                <Input
                  type="date"
                  name="project_start_date"
                  value={formDetails.project_start_date}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl
                id="project_duration"
                isRequired
                hidden={currentStep !== 3}
              >
                <FormLabel>Project Duration</FormLabel>
                <Select
                  name="project_duration"
                  value={formDetails.project_duration}
                  onChange={handleInputChange}
                  defaultValue="Select option"
                  focusBorderColor="brand.400"
                >
                  <option value="Select option" disabled>
                    Select option
                  </option>
                  <option value="1">1. &nbsp; ~ 6 months</option>
                  <option value="2">2. &nbsp; 6 months ~ 1 year</option>
                  <option value="3">3. &nbsp; 1 year ~ 3 years</option>
                  <option value="4">4. &nbsp; 3 years ~ 5 years</option>
                  <option value="5">5. &nbsp; 5 years ~ 10 years</option>
                  <option value="6">6. &nbsp; 10 years ~</option>
                </Select>
              </FormControl>

              <FormControl
                id="suggested_price"
                isRequired
                hidden={currentStep !== 3}
              >
                <FormLabel>Suggested Price</FormLabel>
                <FormHelperText>Currency</FormHelperText>
                <Select
                  name="suggested_price_currency"
                  value={formDetails.suggested_price_currency}
                  onChange={handleInputChange}
                  defaultValue="Select option"
                  focusBorderColor="brand.400"
                  mt={2}
                >
                  <option value="Select option" disabled>
                    Select option
                  </option>
                  <option value="JPY">JPY</option>
                  <option value="USD">USD</option>
                </Select>

                <FormHelperText>Price</FormHelperText>
                <Input
                  type="number"
                  name="suggested_price"
                  value={formDetails.suggested_price}
                  onChange={handleInputChange}
                  placeholder="Ask price of the project"
                  mt={2}
                />
              </FormControl>

              <FormControl
                id="project_image_url"
                isRequired
                hidden={currentStep !== 4}
              >
                <FormLabel>Project Image</FormLabel>
                <FormHelperText>
                  Image that represents the project
                </FormHelperText>
                <Input
                  type="file"
                  name="project_image_url"
                  value={formDetails.project_image_url}
                  onChange={handleProjectImageUpload}
                  mt={2}
                  p={1}
                />
              </FormControl>

              <FormControl id="project_file_url" hidden={currentStep !== 4}>
                <FormLabel>Project File</FormLabel>
                <FormHelperText>PDF document about the project</FormHelperText>
                <Input
                  type="file"
                  name="project_file_url"
                  value={formDetails.project_file_url}
                  onChange={handleProjectFileUpload}
                  mt={2}
                  p={1}
                />
              </FormControl>

              <Checkbox
                id="disclaimerChecked"
                name="disclaimerChecked"
                value={disclaimerChecked}
                onChange={handleDisclaimerCheck}
                mt={10}
                hidden={currentStep !== 5}
                size={"lg"}
              >
                <Text fontWeight={400} fontSize="lg" ml={4}>
                  Agree to the{" "}
                  <Link
                    color="ORANGE.4"
                    href="/terms-and-conditions"
                    isExternal
                  >
                    terms and conditions
                  </Link>{" "}
                  and{" "}
                  <Link color="ORANGE.4" href="/privacy-policy" isExternal>
                    privacy policy
                  </Link>{" "}
                  and confirm the{" "}
                  <Link
                    color="ORANGE.4"
                    onClick={() => alert("Disclaimer clicked")}
                  >
                    disclaimer
                  </Link>{" "}
                  .
                </Text>
              </Checkbox>

              {/* Submit */}
              <Button
                type="submit"
                isDisabled={!disclaimerChecked}
                onClick={handleSubmit}
                bg="ScienceDeals.500"
                color="Neutral.50"
                fontWeight={500}
                fontSize="md"
                size="lg"
                mt={12}
                _hover={{
                  bg: "ScienceDeals.600",
                }}
                _disabled={{
                  bg: "ScienceDeals.400",
                  cursor: "not-allowed",
                }}
                hidden={currentStep !== 5}
              >
                Create
              </Button>
            </VStack>

            {/* Back / Next Buttons */}
            <Button
              isDisabled={currentStep === 1}
              w="100px"
              bg="ORANGE.1"
              color="ORANGE.4"
              fontWeight={500}
              position="absolute"
              bottom="10%"
              left="12%"
              borderRadius="lg"
              _hover={{
                bg: "ORANGE.2",
              }}
              _disabled={{
                bg: "gray.100",
                color: "gray.400",
                cursor: "not-allowed",
              }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Text
              as="span"
              color="gray.300"
              fontWeight={500}
              fontSize="md"
              position="absolute"
              bottom="11%"
              right="47%"
            >
              {currentStep} / 5
            </Text>
            <Button
              isDisabled={currentStep === 5}
              w="100px"
              bg="ORANGE.1"
              color="ORANGE.4"
              fontWeight={500}
              position="absolute"
              bottom="10%"
              right="12%"
              borderRadius="lg"
              _hover={{
                bg: "ORANGE.2",
              }}
              _disabled={{
                bg: "gray.200",
                color: "gray.400",
                cursor: "not-allowed",
              }}
              onClick={handleNext}
            >
              Next
            </Button>
          </Box>

          {/* Loading Spinner */}
          <Modal isOpen={isOpen} onClose={() => {}} isCentered size="xs">
            <ModalOverlay />
            <Spinner
              size="xl"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          </Modal>
        </Flex>
      </Box>
    </Flex>
  );
}
