import axios from "axios";
import React, { useState } from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { useFormData } from "./FormDataContext";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_SRA_API_HOST =
  process.env[`REACT_APP_MERITO_SRA_API_HOST_${MERITO_ENVIRONMENT}`];

export const SRAGeneration = () => {
  const endpoint = "/generate-pdf";
  const navigate = useNavigate();
  const { formData: contextFormData, updateFormData } = useFormData();
  const [assignmentDetails, setAssignmentDetails] = useState({
    project_owner: "",
    project_name: "",
    organization: "",
    asset_type: "",
    amount: "",
  });

  const getCurrentJSTDateTime = () => {
    const jstMoment = moment.tz("Asia/Tokyo");
    // ISO 8601 format with timezone
    const jstString = jstMoment.format();
    return jstString;
  };

  const handlePDFGeneration = async (event) => {
    event.preventDefault();

    // Get a string of YYYY-MM-DD
    const currentJSTDateTime = getCurrentJSTDateTime();
    const currentJSTDate = currentJSTDateTime.split("T")[0];

    try {
      console.log(`Calling Merito SRA API: ${endpoint}`);
      const response = await axios.post(`${MERITO_SRA_API_HOST}${endpoint}`, {
        project_owner: assignmentDetails.project_owner,
        project_name: assignmentDetails.project_name,
        organization: assignmentDetails.organization,
        asset_type: assignmentDetails.asset_type,
        amount: assignmentDetails.amount,
        created_at: currentJSTDate,
      });

      const data = response.data;
      const pdfPath = data.url;
      console.log("\ndata:", data);
      alert(`PDF generated successfully! Path: ${pdfPath}`);
      // setMessage(msg);  // TODO
    } catch (err) {
      const errMsg = `Failed to generate a PDF file:\n${err}`;
      console.log(errMsg);
      alert(errMsg);
      // setErrorMessage(errMsg);  // TODO
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAssignmentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    updateFormData({ [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/create-project-step-3");
  };

  return (
    <Flex
      width="full"
      h={"full"}
      align="top"
      justifyContent="center"
      minHeight="80vh"
      py={20}
    >
      <Box
        p={10}
        maxWidth="700px"
        borderWidth={1}
        borderRadius={8}
        boxShadow="lg"
        bg="white"
      >
        <VStack spacing={4} as="form" onSubmit={handleSubmit} align="stretch">
          <Text color="ScienceDeals.500" fontSize={"5xl"} fontWeight={500}>
            SRA Form
          </Text>
          {/* <Text fontSize="md">Assignment</Text> */}
          <Text fontSize="md">
            {/* This agreement will assign the rights of the patent to the IP-NFT. */}
            SRA PDF document will be generated after you fill out the details
            and press Submit.
          </Text>

          <FormControl id="project_owner" isRequired>
            <FormLabel>Project Owner</FormLabel>
            <Input
              type="text"
              name="project_owner"
              value={assignmentDetails.project_owner}
              onChange={handleInputChange}
              placeholder="Name of the project owner"
            />
          </FormControl>

          <FormControl id="project_name" isRequired>
            <FormLabel>Project Name</FormLabel>
            <Input
              type="text"
              name="project_name"
              value={assignmentDetails.project_name}
              onChange={handleInputChange}
              placeholder="Name of the project"
            />
          </FormControl>

          <FormControl id="organization" isRequired>
            <FormLabel>Organization</FormLabel>
            <Input
              type="text"
              name="organization"
              value={assignmentDetails.organization}
              onChange={handleInputChange}
              placeholder="Name of the organization"
            />
          </FormControl>

          <FormControl id="asset_type" isRequired>
            <FormLabel>Asset Type</FormLabel>
            <Input
              type="text"
              name="asset_type"
              value={assignmentDetails.asset_type}
              onChange={handleInputChange}
              placeholder="Equity, Debt"
            />
          </FormControl>

          <FormControl id="amount" isRequired>
            <FormLabel>Amount</FormLabel>
            <Input
              type="text"
              name="amount"
              value={assignmentDetails.amount}
              onChange={handleInputChange}
              placeholder="%, $"
            />
          </FormControl>

          {/* <Button type="submit" colorScheme="green" size="lg" fontSize="md">
            Submit
          </Button> */}
          <Button
            w="40"
            type="submit"
            onClick={handlePDFGeneration}
            bg="ScienceDeals.500"
            color="Neutral.50"
            fontWeight={500}
            fontSize="md"
            size="lg"
            mt={2}
            _hover={{
              bg: "ScienceDeals.600",
            }}
            _disabled={{
              bg: "ScienceDeals.400",
              cursor: "not-allowed",
            }}
          >
            Generate PDF
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};
