// FormDataContext.js
import React, { createContext, useState, useContext } from "react";

const FormDataContext = createContext(null);

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    contractTitle: "",
    contractDate: "",
    contractOwner: "",
    contractType: "",
    contractFilePath: "",
    userName: "",
    assigneeEntityName: "",
    targetWalletAddress: "",
    imageFilePath: "",
    nftId: "",
    country: "",
    askPrice: 0,
    contractDescription: "",
    // contractWebsite: "",
  });

  const updateFormData = (newData) => {
    console.log("Updating form context: ", newData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...newData,
    }));
  };

  return (
    <FormDataContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);
