import axios from "axios";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Link,
  Button,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, getIdToken } from "firebase/auth";
import moment from "moment-timezone";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];

export default function EmailVerification({
  email,
  phoneNumber,
  firstName,
  lastName,
  birthday,
  username,
  userImageUrl,
  setAccountCreated,
}) {
  const endpoint = "/user/sign_up";
  const navigate = useNavigate();
  const auth = getAuth();

  const [emailVerified, setEmailVerified] = useState(false);
  // const [message, setMessage] = useState(null);  // TODO
  // const [errorMessage, setErrorMessage] = useState(null);  // TODO

  // const validateInput = () => {};  // TODO

  const getCurrentJSTDateTime = () => {
    const jstMoment = moment.tz("Asia/Tokyo");
    // ISO 8601 format with timezone
    const jstString = jstMoment.format();
    return jstString;
  };

  useEffect(() => {
    const checkEmailVerified = async () => {
      // Reload the user to check if email is verified
      await auth.currentUser.reload();
      const isVerified = auth.currentUser.emailVerified;
      setEmailVerified(isVerified);

      if (isVerified) {
        // Send the ID token and additional user data to Merito API
        const idToken = await getIdToken(auth.currentUser, true);
        const response = await axios.post(`${MERITO_API_HOST}${endpoint}`, {
          id_token: idToken,
          email: email,
          phone_number: phoneNumber,
          first_name: firstName,
          last_name: lastName,
          birthday: birthday,
          username: username,
          user_image_url: userImageUrl, // TODO: Add user image URL
          created_at: getCurrentJSTDateTime(),
        });

        const user = await response.data.user;
        const msg = `Email (${email}) verified and user signed up successfully: ${user}`;
        console.log(msg);
        // setMessage(msg);  // TODO

        navigate("/signin");
        setAccountCreated(false);
      }
    };

    // Check the verification status every 2 seconds
    const interval = setInterval(checkEmailVerified, 2000);
    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [auth]);

  const hideEmail = (email) => {
    // name@host.com -> n***@h***
    if (email) {
      const [user, domain] = email.split("@");
      return `${user[0]}***@${domain[0]}***`;
    }
  };

  return (
    <Text>
      Thank you for signing up!
      <br />
      We have sent a verification email to {hideEmail(email)}.
      <br />
      <br />
      Please check your email and click the link to verify your email address.
      <br />
      <br />
      The page will automatically refresh once your email is verified.
    </Text>
  );
}
