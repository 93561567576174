import {
  Box,
  Heading,
  Flex,
  Container,
  Text,
  Button,
  Stack,
  Image,
  Icon,
  Link,
  useColorModeValue,
  createIcon,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Buyers() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("buyers");

  return (
    <Box>
      <Container maxW={{ base: "3xl", sm: "3xl", md: "3xl", lg: "4xl" }}>
        <Stack
          className={i18n.language === "jp" ? "jp" : "en"}
          as={Box}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 12, md: 16 }}
        >
          <Text
            color={"Neutral.0"}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "3xl", md: "4xl", lg: "4xl" }}
            textAlign={"center"}
          >
            {t("title")}
          </Text>

          <Box
            color="Neutral.900"
            bg="Neutral.100"
            fontWeight="500"
            fontSize={{ base: "lg", sm: "lg", md: "xl", lg: "xl" }}
            textAlign={"left"}
            rounded={"2xl"}
            boxShadow={"2xl"}
            px={{ base: 12, md: 20 }}
            py={10}
          >
            <Text as="span" color={"SecondaryAccent.800"} fontWeight="600">
              {t("description_1", "")}
              <br />
              {t("description_2", "")}
            </Text>
            <br />
            <br />
            <br />
            <Text as="span" color={"SecondaryAccent.900"} fontWeight="600">
              {t("list_title_1", "")}
            </Text>
            <List spacing={3} styleType="disc" pl={5}>
              <ListItem>{t("list_item_1_1", "")}</ListItem>
              <ListItem>{t("list_item_1_2", "")}</ListItem>
              <ListItem>{t("list_item_1_3", "")}</ListItem>
              <ListItem>{t("list_item_1_4", "")}</ListItem>
              <ListItem>{t("list_item_1_5", "")}</ListItem>
              <ListItem>{t("list_item_1_6", "")}</ListItem>
              <ListItem>{t("list_item_1_7", "")}</ListItem>
              <ListItem>{t("list_item_1_8", "")}</ListItem>
            </List>

            <br />
            <Text as="span" color={"SecondaryAccent.900"} fontWeight="600">
              {t("description_3", "")}
              <br />
              {t("description_4", "")}
              <br />
              {t("description_5", "")}
            </Text>

            <br />
            <br />
            <br />
            <Text as="span" color={"SecondaryAccent.800"} fontWeight="600">
              {t("description_6", "")}
            </Text>
            <br />
            {t("description_7", "")}
            <Link
              color="PrimaryAccent.800"
              href="https://forms.gle/3r2a2XxACi2uWyKn8"
              isExternal
            >
              {t("description_8", "")}
            </Link>
            {t("description_9", "")}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
