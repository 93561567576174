import {
  Box,
  Heading,
  Flex,
  Container,
  Text,
  Button,
  Stack,
  Image,
  Icon,
  Link,
  useColorModeValue,
  createIcon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("home");

  return (
    <Box>
      <Container maxW={{ base: "3xl", sm: "3xl", md: "3xl", lg: "4xl" }}>
        <Stack
          as={Box}
          textAlign="left"
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 32 }}
        >
          <Text
            fontWeight={600}
            fontSize={{ base: "4xl", sm: "4xl", md: "5xl", lg: "6xl" }}
            color="Neutral.0"
            // bgGradient="linear(to-r, ScienceDeals.400, PatentDeals.400)"
            // bgClip="text"
            textAlign="left"
            lineHeight="110%"
            px={9}
          >
            {t("title")}
          </Text>
          <Box
            className={i18n.language === "jp" ? "jp" : "en"}
            fontWeight="500"
            fontSize={{ base: "lg", sm: "lg", md: "xl", lg: "xl" }}
            color="Neutral.0"
            bgGradient="linear(to-r, ScienceDeals.800, PatentDeals.800)"
            rounded="2xl"
            boxShadow="2xl"
            px={{ base: 12, md: 20 }}
            py={{ base: 10, md: 16 }}
          >
            <Text
              as="span"
              fontSize={{ base: "xl", sm: "xl", md: "2xl", lg: "2xl" }}
            >
              Merito
            </Text>
            {t("description_1")}
            <Text as="span" color={"SecondaryAccent.200"}>
              {t("description_2", "")}
            </Text>
            {t("description_3", "")}
            <Text as="span" color={"SecondaryAccent.200"}>
              {t("description_4", "")}
            </Text>
            {t("description_5", "")}
            <br />
            {t("description_6", "")}

            <br />
            <br />
            <Text as="span" color={"SecondaryAccent.200"}>
              {t("description_7", "")}
            </Text>

            <br />
            <br />
            <Text as="span">
              {t("description_8", "")}
              <Link color="SecondaryAccent.300" href="/creators">
                {t("creators", "")}
              </Link>
              {t("and", "")}
              <Link color="SecondaryAccent.300" href="/buyers">
                {t("buyers", "")}
              </Link>
              {t("description_9", "")}
            </Text>
          </Box>
          <Stack
            className={i18n.language === "jp" ? "jp" : "en"}
            direction={"column"}
            spacing={3}
            align={"center"}
            alignSelf={"center"}
            position={"relative"}
          >
            <Button
              color={"Neutral.950"}
              bg={"Neutral.100"}
              rounded={"full"}
              boxShadow={"2xl"}
              px={6}
              py={6}
              _hover={{
                color: "Neutral.50",
                bg: "Neutral.700",
                boxShadow: "xl",
              }}
              onClick={() => navigate("/contact")}
            >
              {t("contact_button")}
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
