import React, { useContext } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import { IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../context/AuthContext";
import { userSignOut } from "../firebase-authentication/firebaseConfig";
import { useWallet } from "./WalletProvider";
import LanguagePicker from "./LanguagePicker";
import "../App.css";
import { ReactComponent as MeritoLogo } from "../logo_header.svg";

const MENU_ITEMS = [
  {
    label: "creators",
    href: "/creators",
  },
  {
    label: "buyers",
    href: "/buyers",
  },
];

const USER_ITEMS = [
  {
    label: "sign_in",
    href: "/signin",
  },
  {
    label: "sign_up",
    href: "/signup",
  },
  {
    label: "sign_out",
    onClick: () => {
      userSignOut();
      navigate("/");
    },
  },
  {
    label: "portal",
    href: "/portal",
  },
];

const DesktopParent = ({ items }) => {
  const linkColor = "SecondaryAccent.300";
  const popoverContentBgColor = "Neutral.0";
  const { t, i18n } = useTranslation("header");

  return (
    <Stack
      className={i18n.language === "jp" ? "jp" : "en"}
      direction={"row"}
      spacing={4}
    >
      {items.map((item) => (
        <Box key={item.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                href={!item.children && item.href}
                fontSize={"m"}
                fontWeight={600}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  bg: "Neutral.700",
                }}
                rounded={"lg"}
                px={4}
                py={3}
              >
                {t(item.label)}
                {item.children && (
                  <Icon color={"orange.400"} w={6} h={6} as={ChevronDownIcon} />
                )}
              </Link>
            </PopoverTrigger>

            {item.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {item.children.map((child) => (
                    <DesktopChild key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopChild = ({ label, href, subLabel }) => {
  const { t, i18n } = useTranslation("header");

  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover="orange.50"
    >
      <Stack
        className={i18n.language === "jp" ? "jp" : "en"}
        direction={"row"}
        align={"center"}
      >
        <Box>
          <Text
            transition={"all .3s ease"}
            color="orange.500"
            _groupHover={{ color: "orange.400" }}
            fontWeight={500}
          >
            {t(label)}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color="GREEN.7" w={6} h={6} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileParent = ({ items }) => {
  return (
    <Stack bg="Neutral.900" px={7} display={{ md: "none" }}>
      {items.map((item) => (
        <MobileChild key={item.label} {...item} />
      ))}
    </Stack>
  );
};

const MobileChild = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();
  const { t, i18n } = useTranslation("header");

  return (
    <Stack
      className={i18n.language === "jp" ? "jp" : "en"}
      borderTop={1}
      borderStyle={"solid"}
      borderColor="Neutral.700"
      onClick={children && onToggle}
    >
      <Flex
        as={Link}
        py={4}
        px={2}
        href={href}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color="SecondaryAccent.300"
          _hover={{
            bg: "Neutral.700",
          }}
          rounded={"md"}
          px={4}
          py={2}
        >
          {t(label)}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
            color="orange.400"
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          ml={6}
          pl={2}
          borderLeft={2}
          borderStyle={"solid"}
          borderColor="Neutral.700"
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link
                href={child.href}
                key={child.label}
                fontWeight={500}
                color="gray.600"
                _hover={{
                  color: "orange.400",
                  bg: "orange.50",
                }}
                rounded={"md"}
                px={4}
                py={2}
              >
                {t(child.label)}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

export default function Header() {
  const { currentUser, setCurrentUser, signedIn, setSignedIn } =
    useContext(AuthContext);
  const { isOpen, onToggle } = useDisclosure();
  // const [isConnected, setIsConnected] = useState(false);
  const { isConnected, account, web3, connectWallet } = useWallet();
  const { t, i18n } = useTranslation("header");

  const navigate = useNavigate();

  return (
    <Box position="sticky" top="0">
      <Flex
        align={"center"}
        bg="Neutral.900"
        minH={"60px"}
        py={{ base: 4 }}
        px={{ base: 8 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor="Neutral.700"
        boxShadow={"lg"}
        zIndex={0}
        position="relative"
      >
        {/* Menu Icon for Mobile */}
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            color="Neutral.100"
            _hover={{
              bg: "Neutral.700",
            }}
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={4} h={4} /> : <HamburgerIcon w={6} h={6} />
            }
            variant={"ghost"}
            aria-label={"Toggle Items"}
          />
        </Flex>

        {/* Logo and Name */}
        <Flex
          flex={{ base: 1 }}
          justify={{ base: "center", md: "start" }}
          align={"center"}
        >
          <MeritoLogo
            className="header-logo"
            cursor={"pointer"}
            onClick={() => navigate("/")}
          />
          <Text
            textAlign="center"
            fontSize={{ base: "2xl", md: "3xl", lg: "3xl" }}
            fontWeight={400}
            fontFamily="heading"
            color="Neutral.0"
            ml={2.5}
            cursor={"pointer"}
            onClick={() => navigate("/")}
          >
            Merito
          </Text>
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopParent items={MENU_ITEMS} />
          </Flex>
        </Flex>

        {/* Logged-out */}
        <Stack
          className={i18n.language === "jp" ? "jp" : "en"}
          hidden={signedIn}
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={2}
        >
          {/* <Button
            as={"a"}
            display={{ base: "inline-flex", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color="Neutral.200"
            bg="Neutral.900"
            _hover={{
              bgGradient: "linear(to-b, ScienceDeals.800, PatentDeals.800)",
            }}
            cursor={"pointer"}
            onClick={() => navigate("/signin")}
          >
            {t("sign_in")}
          </Button>
          <Button
            as={"a"}
            display={{ base: "inline-flex", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color="Neutral.200"
            bg="Neutral.600"
            _hover={{
              color: "Neutral.100",
              bgGradient: "linear(to-b, ScienceDeals.800, PatentDeals.800)",
            }}
            cursor={"pointer"}
            onClick={() => navigate("/signup")}
          >
            {t("sign_up")}
          </Button> */}
          <LanguagePicker ml={2} />;
        </Stack>

        {/* Logged-in */}
        <Stack
          className={i18n.language === "jp" ? "jp" : "en"}
          hidden={!signedIn}
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={2}
        >
          {/* <Button
            as={"a"}
            display={{ base: "inline-flex", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color="Neutral.200"
            bg="Neutral.900"
            _hover={{
              bg: "Neutral.700",
            }}
            cursor={"pointer"}
            onClick={() => {
              userSignOut();
              navigate("/");
            }}
          >
            {t("sign_out")}
          </Button>
          <Button
            as={"a"}
            display={{ base: "inline-flex", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={600}
            color="Neutral.200"
            bgGradient="linear(to-b, ScienceDeals.700, PatentDeals.700)"
            _hover={{
              color: "Neutral.100",
              bgGradient: "linear(to-b, ScienceDeals.600, PatentDeals.600)",
            }}
            cursor={"pointer"}
            onClick={() => navigate("/portal")}
          >
            <Icon color="Neutral.100" w={5} h={5} as={IoPerson} />{" "}
            <Text as="span" ml={2}>
              {currentUser?.first_name}
            </Text>
          </Button> */}
          <LanguagePicker ml={2} />;
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileParent items={MENU_ITEMS} />
      </Collapse>
    </Box>
  );
}
