import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider, CSSReset, Flex, VStack } from "@chakra-ui/react";

// General Settings
import theme from "./theme";
import "./i18n";

// Header / Footer
import Header from "./components/Header";
import Footer from "./components/Footer";

// Pages
// General
import Placeholder from "./components/Placeholder";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import Creators from "./components/Creators";
import Buyers from "./components/Buyers";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Portal from "./components/Portal";
import Contact from "./components/Contact";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";

// Science Deals
import ScienceDealsDashboard from "./components/ScienceDealsDashboard";
import ScienceDealsCreation from "./components/ScienceDealsCreation";
import ScienceDealsListing from "./components/ScienceDealsListing";
import Project from "./components/Project";
import ScienceDeal from "./components/ScienceDeal";

// Patent Deals
import PatentDealsDashboard from "./components/PatentDealsDashboard";

// Prototype
import { SRAGeneration } from "./components/SRAGeneration";
import { ImageUploadForm } from "./components/ImageUploadForm";
import SummaryFormComponent from "./components/SummaryFormComponent";
import ProjectsGrid from "./components/ProjectsGrid";
// import ProjectDetail from "./components/ProjectDetail";

// Web3 / Not used
import { WalletProvider } from "./components/WalletProvider";
import { FormDataProvider } from "./components/FormDataContext";
import { ResearchAgreementForm } from "./components/ResearchAgreementForm";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <WalletProvider>
        <FormDataProvider>
          <Router>
            <Flex
              // className={i18n.language === "jp" ? "jp" : "en"}
              className="en"
              bg="Neutral.950"
              flexDirection="column"
              minHeight="100vh"
            >
              <Header />
              <VStack as="main" flex="1">
                <Routes>
                  {/* Header */}
                  <Route path="/creators" element={<Creators />} />
                  <Route path="/buyers" element={<Buyers />} />
                  {/* <Route path="/science-deals" element={<Placeholder />} />
                  <Route path="/patent-deals" element={<Placeholder />} /> */}
                  {/* <Route path="/signin" element={<SignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/portal" element={<Portal />} /> */}

                  {/* Footer */}
                  <Route path="/about-us" element={<AboutUs />} />

                  {/* General */}
                  <Route path="/" element={<Home />} />
                  <Route path="/contact" element={<Contact />} />
                  {/* <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}

                  {/* Portal */}
                  {/* <Route path="/user" element={<Placeholder />} />
                  <Route path="/user/:userId" element={<Placeholder />} />
                  <Route
                    path="/science-deals/dashboard"
                    element={<ScienceDealsDashboard />}
                  />
                  <Route
                    path="/patent-deals/dashboard"
                    element={<Placeholder />}
                  /> */}

                  {/* Science Deals */}
                  {/* <Route
                    path="/science-deals/creation"
                    element={<ScienceDealsCreation />}
                  />
                  <Route
                    path="/science-deals/sra-generation"
                    element={<SRAGeneration />}
                  />
                  <Route
                    path="/science-deals/listing"
                    element={<ScienceDealsListing />}
                  /> */}

                  {/* Patent Deals */}
                  {/* <Route
                    path="/patent-deals/creation"
                    element={<Placeholder />}
                  />
                  <Route
                    path="/patent-deals/listing"
                    element={<Placeholder />}
                  /> */}

                  {/* Prototype */}
                  {/* <Route
                    path="/create-project-step-2"
                    element={<AssignmentForm />}
                  />
                  <Route
                    path="/create-project-step-3"
                    element={<ImageUploadForm />}
                  />
                  <Route
                    path="/create-project-step-4"
                    element={<SummaryFormComponent />}
                  />
                  <Route
                    path="/demo-view-projects"
                    element={<ProjectsGrid />}
                  />
                  <Route path="/project/:id" element={<ProjectDetail />} /> */}
                </Routes>
              </VStack>
              <Footer />
            </Flex>
          </Router>
        </FormDataProvider>
      </WalletProvider>
    </ChakraProvider>
  );
}

export default App;
