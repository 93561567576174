import axios from "axios";
import React, { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";

import { getCurrentUser } from "../../firebase-authentication/firebaseConfig";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [signedIn, setSignedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      const user = await getCurrentUser(firebaseUser);
      if (user && user.length) {
        console.log("Current User:", user);
        setCurrentUser(user);
        setSignedIn(true);
      } else {
        setCurrentUser(null);
        setSignedIn(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider
      value={{ currentUser, setCurrentUser, signedIn, setSignedIn }}
    >
      {children}
    </AuthContext.Provider>
  );
};
