import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    // OLD
    GREEN: {
      1: "#FFFFFF", // White
      2: "#F3F8F6", // Lightest green
      3: "#DFEBE7",
      4: "#C4DAD0",
      5: "#95BBAC",
      6: "#6BA08B",
      7: "#418569",
      8: "#217151",
      9: "#065838",
      10: "#00422A", // Darkest green
    },
    ORANGE: {
      1: "#FFE8D0", // Lightest orange
      2: "#FFD4A8",
      3: "#FFB15F",
      4: "#FF9F3A",
      5: "#FF901B", // Darkest orange
    },
    // NEW
    ScienceDeals: {
      // Forest Green
      50: "#F4F9F7", // Lightest
      100: "#DBECE5",
      200: "#B8D7CC",
      300: "#8CBCAC",
      400: "#649D8D",
      500: "#4A8273", // Mid
      600: "#39685C",
      700: "#31544B",
      800: "#2A453F",
      900: "#263B36",
      950: "#12211F", // Darkest
    },
    PatentDeals: {
      // Dusty Rose
      50: "#F9F6F8", // Lightest
      100: "#F5EEF1",
      200: "#ECDEE5",
      300: "#DEC3CF",
      400: "#C89EB0",
      500: "#B47F94", // Mid
      600: "#9E6478",
      700: "#865061",
      800: "#704452",
      900: "#5F3C47",
      950: "#372027", // Darkest
    },
    PrimaryAccent: {
      // Bright Blue
      // Buttons, CTAs
      50: "#EDFAFF",
      100: "#D6F2FF",
      200: "#B5EAFF",
      300: "#83DFFF",
      400: "#48CBFF",
      500: "#1EADFF",
      600: "#068FFF",
      700: "#007BFF", // Main
      800: "#085EC5",
      900: "#0D519B",
      950: "#0E315D",
    },
    SecondaryAccent: {
      // Bright Orange
      // Secondary Actions
      50: "#FFF4ED",
      100: "#FFE5D4",
      200: "#FFC7A8",
      300: "#FFA071",
      400: "#FF7F50", // Main
      500: "#FE4711",
      600: "#EF2D07",
      700: "#C61C08",
      800: "#9D190F",
      900: "#7E1810",
      950: "#440806",
    },
    Highlight: {
      // Bright Yellow
      // Important Text
      50: "#FFFFE7",
      100: "#FEFFC1",
      200: "#FFFD86",
      300: "#FFF441",
      400: "#FFE60D",
      500: "#FFD700", // Main
      600: "#D19E00",
      700: "#A67102",
      800: "#89580A",
      900: "#74480F",
      950: "#442604",
    },
    Neutral: {
      // Light Grey
      // Backgrounds, Borders
      0: "#FFFFFF", // White
      50: "#F5F5F5",
      100: "#EFEFEF",
      200: "#DCDCDC",
      300: "#BDBDBD",
      400: "#989898",
      500: "#7C7C7C", // Mid
      600: "#656565",
      700: "#525252",
      800: "#464646",
      900: "#3D3D3D",
      950: "#292929",
      1000: "#000000", // Black
    },
  },
});

export default theme;
