import axios from "axios";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
} from "firebase/auth";

const MERITO_ENVIRONMENT = process.env.REACT_APP_MERITO_ENVIRONMENT;
const MERITO_API_HOST =
  process.env[`REACT_APP_MERITO_API_HOST_${MERITO_ENVIRONMENT}`];

const firebaseConfig = {
  apiKey:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_APIKEY_${MERITO_ENVIRONMENT}`
    ],
  authDomain:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_AUTHDOMAIN_${MERITO_ENVIRONMENT}`
    ],
  projectId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_PROJECTID_${MERITO_ENVIRONMENT}`
    ],
  storageBucket:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_STORAGEBUCKET_${MERITO_ENVIRONMENT}`
    ],
  messagingSenderId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_MESSAGINGSENDERID_${MERITO_ENVIRONMENT}`
    ],
  appId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_APPID_${MERITO_ENVIRONMENT}`
    ],
  measurementId:
    process.env[
      `REACT_APP_MERITO_FIREBASE_AUTHENTICATION_MEASUREMENTID_${MERITO_ENVIRONMENT}`
    ],
};

// Initialize Firebase and get auth instance
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore();
// const auth = getAuth(firebaseApp);
// export default auth;

export const userSignUp = (email, password) => {
  const auth = getAuth();
  return createUserWithEmailAndPassword(auth, email, password);
};

export const userSignIn = (email, password) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

export const userSignOut = () => {
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      console.log("User signed out");
    })
    .catch((error) => {
      console.error("Failed to sign out", error.message || error);
    });
};

export const getCurrentUser = async (firebaseUser) => {
  if (!firebaseUser) {
    return null;
  }

  try {
    const idToken = await getIdToken(firebaseUser, true);
    const response = await axios.post(`${MERITO_API_HOST}/user/sign_in`, {
      id_token: idToken,
    });
    const userData = response.data.user;
    console.log("Current User Data:", userData);
    return userData;
  } catch (error) {
    console.error("Error during sign-in:", error);
  }
};
